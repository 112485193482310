<template>
  <transition name="slide">
    <div class="sercice">
      <h :isback="true">
        <span class="name" @click="showGuestTips">
          邀请用户<i class="icon-question">?</i>
        </span>
      </h>
      <div class="main">
        <cube-scroll ref="mainScroll" :options="options">
          <div class="title">
            <span>我的邀请码</span>
            <img src="./img/guest-code.png" alt="我的邀请码" />
          </div>
          <div class="code-box" @click="copy">
            <span class="num">{{ userInfo.code }}</span>
            <input
              ref="linkCopy"
              style="position: absolute;opacity: 0;width: 1%;"
              type="text"
            />
            <img class="icon-copy" src="./img/guest-copy.png" alt="复制图标" />
          </div>
          <div class="guest-list-box">
            <div class="top">
              已邀请用户 <span v-show="list.length">({{ list.length }}人)</span>
            </div>
            <div class="scroll-list-wrap">
              <cube-scroll ref="scroll" :data="list" :options="options">
                <ul v-show="list.length" class="list">
                  <li v-for="guest in list" :key="guest.id" class="item">
                    <div class="avatar">
                      <img
                        :src="guest.avatar ? guest.avatar : avatar"
                        alt="头像"
                      />
                    </div>
                    <div class="name">{{ guest.nickName }}</div>
                  </li>
                </ul>
                <div v-show="!list.length" class="not-data">
                  <p class="tips">暂无邀请</p>
                  <p class="sub-tips">邀请新用户可以获得丰厚奖励！</p>
                </div>
              </cube-scroll>
            </div>
          </div>
          <div class="btn-box" @click="shareWinRef">邀请</div>
        </cube-scroll>
      </div>
      <share-win :config="config" :user="userInfo" ref="shareWinRef" />
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
import shareWin from "@/components/user/guest/share-win";
import { getGuestConfig, getGuestList } from "@/api/user";
import { mapState, mapMutations } from "vuex";
import { avatar } from "@/api/config";
export default {
  components: {
    h,
    shareWin
  },
  data() {
    return {
      config: {},
      userInfo: {},
      list: [],
      options: {
        click: true
      },
      content: "",
      avatar
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.info
    })
  },
  created() {
    this._getGuestConfig();
    this._getGuestList();
  },
  methods: {
    ...mapMutations({
      toast: "global/SET_TOAST"
    }),
    _getGuestConfig() {
      const params = {
        userId: this.user.id
      };
      getGuestConfig(params).then(res => {
        if (!res) return;
        this.userInfo = res.object.userInfo;
        this.config = res.object.config;
      });
    },
    _getGuestList() {
      const params = {
        userId: this.user.id
      };
      getGuestList(params).then(res => {
        if (!res) return;
        this.list = res.object.content;
      });
    },
    showGuestTips() {
      if (!this.config.rule) return;
      this.$createImagePreview({
        imgs: [this.config.rule]
      }).show();
    },
    copy() {
      const copyDom = this.$refs.linkCopy;
      copyDom.value = this.userInfo.code;
      copyDom.select();
      document.execCommand("copy");
      this.$createDialog({
        type: "alert",
        title: "提示",
        content: "复制成功",
        icon: "cubeic-alert"
      }).show();
    },
    shareWinRef() {
      this.$refs.shareWinRef.show();
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.sercice
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #f0f0f0
  z-index 11
  .icon-question
    position relative
    top -2px
    display inline-block
    width 16px
    height 16px
    line-height 14px
    margin-left 2px
    border 1px solid #fff
    border-radius 50%
    font-size 12px
    font-style normal
    text-align center
  .main
    position relative
    width 100%
    height 100%
    .title
      padding 20px 0
      font-size 0
      line-height 1
      text-align center
      span
        display inline-block
        vertical-align middle
        margin-right 7px
        font-size 14px
        color #383A40
      img
        width 12px
        vertical-align middle
    .code-box
      position relative
      width 240px
      height 50px
      line-height 50px
      background #FFFFFF
      box-shadow 0px 2px 3px 0px rgba(39, 144, 245, 0.39)
      margin 0 auto
      text-align center
      .num
        font-size 24px
        font-weight bold
        color #383A40
      .icon-copy
        position absolute
        width 18px
        top 16px
        right 11px
    .guest-list-box
      position relative
      margin-top 30px
      .top
        margin-bottom 15px
        line-height 1.5
        font-size 12px
        color #6B6B6B
        text-align center
        &:before
          display inline-block
          content ''
          vertical-align middle
          width 110px
          height 5px
          background linear-gradient(-90deg, #2A99FA, rgba(255, 255, 255, 0))
          border-radius 3px
          margin-right 5px
        &:after
          display inline-block
          content ''
          vertical-align middle
          width 110px
          height 5px
          background linear-gradient(-90deg, rgba(255, 255, 255, 0), #2A99FA)
          border-radius 3px
          margin-left 5px
      .scroll-list-wrap
        width 372px
        height 300px
        margin 0 auto
        padding 17px 26px
        border-radius 8px
        background #fff
        .list
          display flex
          flex-wrap wrap
          .item
            flex 0 0 25%
            padding-bottom 23px
            text-align center
            .avatar
              img
                width 57px
                height 57px
                border-radius 50%
            .name
              font-size 12px
              color #383A40
        .not-data
          padding-top 50px
          text-align center
          .tips
            margin-bottom 15px
            font-size 19px
            font-weight bold
            color #000
          .sub-tips
            font-size 18px
            color #383A40
    .btn-box
      width 150px
      height 44px
      line-height 44px
      margin 20px auto
      background linear-gradient(-55deg, #1B64DC, #2A99FA)
      box-shadow 0px 2px 4px 0px rgba(39, 144, 245, 0.68)
      border-radius 22px
      text-align center
      font-size 20px
      font-weight bold
      color #fff
</style>
