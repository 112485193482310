<template>
  <div v-if="showFlag" class="guest-share-win" @click.self="hide">
    <div class="content">
      <div class="title">选择海报</div>
      <ul class="theme-list">
        <li
          v-show="config.poster1"
          class="item"
          :class="{ active: defaultPoster == config.poster1 }"
          @click="selectPoster(config.poster1)"
        >
          <img class="poster" :src="config.poster1" alt="海报" />
          <img class="sel" src="./img/sel.png" alt="选中" />
        </li>
        <li
          v-show="config.poster2"
          class="item"
          :class="{ active: defaultPoster == config.poster2 }"
          @click="selectPoster(config.poster2)"
        >
          <img class="poster" :src="config.poster2" alt="海报" />
          <img class="sel" src="./img/sel.png" alt="选中" />
        </li>
        <li
          v-show="config.poster3"
          class="item"
          :class="{ active: defaultPoster == config.poster3 }"
          @click="selectPoster(config.poster3)"
        >
          <img class="poster" :src="config.poster3" alt="海报" />
          <img class="sel" src="./img/sel.png" alt="选中" />
        </li>
      </ul>
      <div class="share-channel-box">
        <div class="desc">分享到</div>
        <ul class="list">
          <li class="item" @click="copy">
            <div class="icon">
              <img src="./img/link.png" alt="复制链接" />
            </div>
            <input
              ref="linkCopy"
              style="position: absolute;opacity: 0;width: 1%;"
              type="text"
            />
            <div class="text">复制链接</div>
          </li>
          <li class="item" @click="getDomImage">
            <div class="icon">
              <img src="./img/save.png" alt="保存图片" />
            </div>
            <div class="text">保存图片</div>
          </li>
        </ul>
      </div>
      <hr class="gap" />
      <cube-button :light="true" @click.stop="hide">取消</cube-button>
    </div>
    <div ref="theme" class="canvas-poster">
      <div class="poster-image">
        <img :src="defaultPoster" alt="海报" />
      </div>
      <div class="user-info">
        <div class="avatar">
          <img :src="user.avatar" alt="头像" />
        </div>
        <div class="text">
          <p class="name">{{ user.nickName }}</p>
          <p class="code">邀请码：{{ user.code }}</p>
        </div>
        <div class="code-image">
          <img src="./img/doupai-code.png" alt="斗牌" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import html2canvas from "html2canvas";
export default {
  props: {
    config: {
      type: Object,
      default() {
        return {};
      }
    },
    user: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showFlag: false,
      defaultPoster: ""
    };
  },
  methods: {
    show() {
      this.showFlag = true;
      this.defaultPoster =
        this.config.poster1 || this.config.poster2 || this.config.poster3;
    },
    hide() {
      this.showFlag = false;
    },
    selectPoster(poster) {
      this.defaultPoster = poster;
    },
    copy() {
      const copyDom = this.$refs.linkCopy;
      copyDom.value = "www.doupai.tv";
      copyDom.select();
      document.execCommand("copy");
      this.$createDialog({
        type: "alert",
        title: "提示",
        content: "复制链接成功",
        icon: "cubeic-alert"
      }).show();
    },
    getDPR() {
      if (window.devicePixelRatio && window.devicePixelRatio > 1) {
        return window.devicePixelRatio;
      }
      return 1;
    },
    getDomImage() {
      const dom = this.$refs.theme;
      const width = window.getComputedStyle(dom).width.replace("px", "");
      const height = window.getComputedStyle(dom).height.replace("px", "");
      const scaleBy = this.getDPR();
      // 创建自定义 canvas 元素
      const canvas = document.createElement("canvas");
      // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
      canvas.width = width * scaleBy;
      canvas.height = height * scaleBy;
      // 设定 canvas css宽高为 DOM 节点宽高
      canvas.style.width = `${width}px`;
      canvas.style.height = `${height}px`;
      // 获取画笔
      const context = canvas.getContext("2d");
      // 将所有绘制内容放大像素比倍
      context.scale(scaleBy, scaleBy);
      var rect = dom.getBoundingClientRect(); //获取元素相对于视察的偏移量
      context.translate(-rect.left, -rect.top); //设置context位置，值为相对于视窗的偏移量负值，让图片复位
      html2canvas(dom, { useCORS: true, ...canvas }).then(res => {
        let img = document.createElement("a");
        img.href = res
          .toDataURL("image/jpeg")
          .replace("image/jpeg", "image/octet-stream");
        img.download = "doupai.jpg";
        img.click();
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
.guest-share-win
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  background rgba(0, 0, 0, .5)
  z-index 98
  .content
    position absolute
    left 0
    right 0
    bottom 0
    background #fff
    border-top-left-radius 8px
    border-top-right-radius 8px
    z-index 1
    .title
      padding 13px 9px
      font-size 14px
      color #000
      text-align center
    .theme-list
      display flex
      flex-wrap wrap
      justify-content space-between
      .item
        position relative
        &:after
          position absolute
          top 0
          right 0
          bottom 0
          left 0
          display block
          content ''
          background rgba(0, 0, 0, .5)
        &.active
          &:after
            display none
          .sel
            opacity 1
        .poster
          width 108px
        .sel
          opacity 0
          position absolute
          right 4px
          bottom 5px
          width 16px
    .share-channel-box
      margin-top 12px
      .desc
        padding-bottom 7px
        font-size 14px
        color #000
        text-align center
      .list
        display flex
        flex-wrap wrap
        justify-content center
        .item
          position relative
          margin-right 59px
          &:last-child
            margin-right 0
          .icon
            img
              width 63px
              height 63px
              border-radius 50%
          .text
            font-size 12px
            color #000
            text-align center
    .gap
      margin 5px 0
      height 5px
      background #E9E9E9
      border none
  .canvas-poster
    position absolute
    top -100%
    left 0
    right 0
    width 375px
    z-index 0
    .poster-image
      width 100%
    .user-info
      display flex
      align-items center
      padding 12px 12px 13px
      background #fff
      .avatar
        flex 0 0 75px
        img
          width 75px
          height 75px
          border-radius 50%
      .text
        margin-left 8px
        flex 1
        font-size 15px
        line-height 1.5
        color #000
      .code-image
        flex 0 0 75px
        img
          width 75px
          height 75px
</style>
